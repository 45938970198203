<template>
  <div
    id="tpl-events"
    class="template-events-4"
    v-if="candidate.events.length > 0"
  >
    <b-container>
      <div class="text-center">
        <h3 class="mb-5">Upcoming Events</h3>
      </div>
      <div>
        <b-card-group deck>
          <b-card
            align="center"
            :title="event.title"
            v-for="(event, i) in candidate.events"
            :key="i"
          >
            <template #header>
              <div class="event-date">
                <h4 class="mb-0 month">
                  <time>{{ getEventMonth(event) }}</time>
                </h4>
                <h5 class="day">
                  <time>{{ getEventDay(event) }}</time>
                </h5>
              </div>
            </template>
            <b-card-text class="event-time">
              <time>{{ event.dateTimeText }}</time>
            </b-card-text>
            <b-card-text class="mb-4">
              {{ event.description }}
            </b-card-text>
            <b-card-text>
              <a
                :href="event.ctaLink"
                class="btn btn-primary text-decoration-none"
                role="button"
                >{{ event.ctaText }}</a
              >
            </b-card-text>
          </b-card>
        </b-card-group>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Events",
    };
  },
  computed: {
    candidate() {
      return this.$store.getters["candidates/candidate"];
    },
  },
  methods: {
    getEventMonth(event) {
      var dates = new Date(event.dateTimeText).toString().split(" ");
      return dates[1];
    },
    getEventDay(event) {
      var dates = new Date(event.dateTimeText).toString().split(" ");
      return dates[2];
    },
  },
};
</script>

<style lang="scss" scoped></style>
